import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";

const AdminPermissions = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [roles, setRoles] = useState([]);
  const [matrix, setMatrix] = useState([]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    fetchMatrix();
  }, []);

  const fetchMatrix = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getPermissionsMatrix`);
      setRoles(response.data.roles);
      setMatrix(response.data.matrix);
    } catch (error) {
      console.error("Error fetching permissions matrix:", error);
    }
  };

  const handleCheckboxChange = async (roleId, reportId, currentView) => {
    try {
      await axios.post(`${BASE_URL}/api/updatePermissionForRole`, {
        roleId,
        reportId,
        view: !currentView,
      });
      fetchMatrix(); // Refresh the matrix after update
    } catch (error) {
      console.error("Error updating permission:", error);
    }
  };

  return (
    <section>
      <div className="d-flex">
        <div style={{ width: "14%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>
        <div
          className=""
          style={{
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
              padding: "10px",
              
            }}
          >
           <div style={{ height: "9%", marginBottom: '10px', }}>
           <h1>Permissions</h1>
           </div>
            <div className="table-responsive" style={{ height: "90%" }}>
              <table className="table table-striped table-bordered">
                <thead style={{position: 'sticky', top: 0, backgroundColor: '#252525', color: '#fafafa', border: '#343434', zIndex: 1}}>
                  <tr >
                    <th style={{fontWeight: '400'}}>Reports</th>
                    {roles.map((role) => (
                      <th key={role.id} style={{fontWeight: '400'}}>{role.roleName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {matrix.map((row) => (
                    <tr key={row.reportId}>
                      <td>{row.reportName}</td>
                      {roles.map((role) => (
                        <td key={`${role.id}-${row.reportId}`}>
                          <input
                            type="checkbox"
                            checked={row[role.roleName] || false}
                            onChange={() =>
                              handleCheckboxChange(
                                role.id,
                                row.reportId,
                                row[role.roleName]
                              )
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminPermissions;

import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import Sidebar from "../components/Sidebar";
import { Form, Row, Col } from "react-bootstrap";
import {
  getFirstAndLastEmiDates,
  calculateEmiAmount,
} from "../components/utils";
import Select from "react-select";
import CustomPagination from "../components/CustomPagination";
import DataTable from "../components/DataTable";

const LoanDisbursedReport = () => {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBranchFilter, setSelectedBranchFilter] = useState(null); // State for branch name filter
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(null); // State for employee name filter

  const itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginationData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getLoanDisbursementData`
        );
        // console.log(
        //   "loanDisbursed response.data: " + JSON.stringify(response.data)
        // );
        if (response.data) {
          setReportData(response.data);
        } else {
          console.log("Error in fetching Data");
        }
      } catch (error) {
        console.log("Error fetching Data:", error);
      }
    };

    getReportData();
  }, []);

  // Extract unique branch names and employee names for dropdown options
  const branchOptions = [
    ...new Set(filteredData.map((data) => data?.branchName)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  const employeeOptions = [
    ...new Set(filteredData.map((data) => data?.username)),
  ]
    .filter((name) => name)
    .map((name) => ({ value: name, label: name }));

  // Automatically filter data when fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filtered = reportData
        .filter((item) => {
          // Filter by Date
          const updatedAt = new Date(item.branchManagerStatusUpdatedAt);
          return (
            updatedAt >= new Date(fromDate) && updatedAt <= new Date(toDate)
          );
        })
        .filter((item) => {
          // Filter by Branch Name
          if (selectedBranchFilter && selectedBranchFilter.value) {
            return item.branchName === selectedBranchFilter.value;
          }
          return true; // Return all items if no branch is selected
        })
        .filter((item) => {
          // Filter by Employee Name
          if (selectedEmployeeFilter && selectedEmployeeFilter.value) {
            return item.username === selectedEmployeeFilter.value;
          }
          return true; // Return all items if no employee is selected
        });

      setFilteredData(filtered);
    } else {
      setFilteredData([]); // Show no data if dates are not selected
    }
  }, [
    fromDate,
    toDate,
    reportData,
    selectedBranchFilter,
    selectedEmployeeFilter,
  ]);

  // Define columns with label and render logic
  const columns = [
    { label: "S No", render: (data, index) => index + 1 },
    { label: "Region", render: (data) => data.regionName },
    { label: "Division", render: (data) => data.divisionName },
    { label: "Branch Name", render: (data) => data.branchName },
    { label: "Branch Code", render: (data) => data.branchCode },
    { label: "Customer Name", render: (data) => data.memberName },
    { label: "Customer ID", render: (data) => data.customerId },
    {
      label: "Address",
      render: (data) =>
        `${data.permanentDoorNo},${data.permanentStreetNo},${data.permanentPanchayat},${data.permanentTaluk},${data.permanentDistrict}-${data.permanentPincode}`,
    },
    { label: "Contact Number", render: (data) => data.phoneNumber },
    { label: "Loan Type", render: (data) => "Business Loan" },
    { label: "Loan Cycle", render: (data) => data.loanCycle },
    { label: "Loan Account No", render: (data) => data.loanId },
    {
      label: "Rate of Interest",
      render: (data) => data.proposedLoanDetails.rateOfInterest,
    },
    {
      label: "Disbursement Date",
      render: (data) =>
        data.branchManagerStatus === "disbursed" &&
        data.branchManagerStatusUpdatedAt
          ? new Date(data.branchManagerStatusUpdatedAt).toLocaleDateString(
              "en-GB",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : "",
    },
    {
      label: "Maturity Date",
      render: (data) => {
        const { lastEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return lastEmiDate ? lastEmiDate.toLocaleDateString() : "";
      },
    },
    {
      label: "Loan Amount",
      render: (data) =>
        data.branchManagerStatus === "disbursed"
          ? data.sanctionedLoanAmountBySanctionCommittee
          : "",
    },
    { label: "Employee Name", render: (data) => data.employeeName },
    {
      label: "Processing Fee",
      render: (data) => (data.processingCharge ? data.processingCharge : ""),
    },
    { label: "GST", render: (data) => (data.gst ? data.gst : "") },
    {
      label: "Insurance",
      render: (data) => (data.isLoanInsured ? data.insuranceAmount : 0),
    },
    {
      label: "Tenure",
      render: (data) => `${data.proposedLoanDetails.tenureInMonths} Months`,
    },
    {
      label: "EMI Amount",
      render: (data) => {
        const emiAmount = calculateEmiAmount(
          data.sanctionedLoanAmountBySanctionCommittee,
          data.proposedLoanDetails.rateOfInterest,
          data.proposedLoanDetails.tenureInMonths
        );
        return emiAmount ? Math.round(emiAmount) : "";
      },
    },
    { label: "Payment Mode", render: () => "Monthly" },
    {
      label: "Installment Start Date",
      render: (data) => {
        const { firstEmiDate } = getFirstAndLastEmiDates(
          data.branchManagerStatusUpdatedAt,
          data.proposedLoanDetails.tenureInMonths,
          data.emiDateByBranchManager
        );
        return firstEmiDate ? firstEmiDate.toLocaleDateString() : "";
      },
    },
    { label: "Purpose", render: (data) => data.businessDetails.businessType },
    // Add more columns as needed with custom render logic
  ];

  return (
    <div className="dashboard-over">
      <div className="d-flex" style={{width: '100vw'}}>
      <div style={{width: '13%'}}>
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        </div>
        
        <div
          className="container-fluid"
          style={{
           // overflowY: "scroll",
           width: '87%',
           height: "100vh",
           // scrollbarWidth: "none",
          }}
        >
          <div style={{height: '23%',  zIndex: '5', position: 'relative'}}>
          <h1>Loan Disbursed Report</h1>
          <div style={{position: 'absolute', width: '100%'}}>
          <Row style={{fontWeight: '500'}}>
            <Col xs={12} lg={2}>
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={2}>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3}>
              <Form.Label>Branch</Form.Label>
              <Select
                value={selectedBranchFilter}
                onChange={setSelectedBranchFilter}
                options={branchOptions}
                isClearable
                placeholder="Filter by Branch Name"
                className="filter-dropdown"
              />
            </Col>
            <Col xs={12} lg={3}>
              <Form.Label>Employee</Form.Label>
              <Select
                value={selectedEmployeeFilter}
                onChange={setSelectedEmployeeFilter}
                options={employeeOptions}
                isClearable
                placeholder="Filter by Employee Name"
                className="filter-dropdown"
              />
            </Col>
          </Row>
          </div>
          </div>

          <div style={{height: '69%',}}>
          <DataTable
            columns={columns}
            data={paginationData}
            reportName="LoanDisbursedReport"
            excelData={filteredData}
          />
          </div>

          <div style={{height: '8%', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            paginate={paginate}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDisbursedReport;

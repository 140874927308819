import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import AddBranchModal from "./AddBranchModal";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import Select from "react-select";
import { IoMdAdd } from "react-icons/io";

const AdminBranchList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [branchesPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [selectedBranch, setSelectedBranch] = useState({
    id: null,
    branchName: "",
    branchCode: "",
    divisionId: null,
  });

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/branches`);

      setBranches(response.data);
      setFilteredBranches(response.data);
      // console.log("BranchList: " + JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/divisions`);

      setDivisions(response.data);
    } catch (error) {
      console.error("Error fetching divisions:", error);
    }
  };

  useEffect(() => {
    const filtered = branches.filter((branch) =>
      branch.branchName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBranches(filtered);
    setCurrentPage(1);
  }, [searchQuery, branches]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddBranchClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditBranch = (branch) => {
    fetchDivisions();
    setSelectedBranch({
      ...branch,
      divisionId: {
        value: branch.division.id,
        label: branch.division.divisionName,
      },
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedBranch(null);
  };

  const handleSaveChanges = async () => {
    if (
      !selectedBranch.branchCode ||
      !selectedBranch.branchName ||
      !selectedBranch.divisionId
    ) {
      setValidationError("Please Enter All Fields");
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}/api/admin/editbranch`, {
        branchId: selectedBranch.id,
        branchName: selectedBranch.branchName,
        branchCode: selectedBranch.branchCode,
        divisionId: selectedBranch.divisionId.value,
      });
      if (response.status === 200) {
        fetchBranches();
        handleCloseEditModal();
      } else {
        setValidationError("Error: " + response.data.error);
        console.error("Failed to update branch details");
      }
    } catch (error) {
      console.error("Error updating branch details:", error);
    }
  };

  const indexOfLastBranch = currentPage * branchesPerPage;
  const indexOfFirstBranch = indexOfLastBranch - branchesPerPage;
  const currentBranches = filteredBranches.slice(
    indexOfFirstBranch,
    indexOfLastBranch
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredBranches.length / branchesPerPage);

  return (
    <section>
      <div className="d-flex">
      <div style={{width: "14%"}}>
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        </div>

        <div
          className=""
          style={{
            height: "100vh",
              width: "86%",
             backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>


          <div style={
            {
              height: "86%",
              width: "100%",
              borderRadius: '40px 0px 0px 0px',
              backgroundColor: "white", 
              
            }
          }> 

          <div className="search-container " style={{height: '13%', }}>
            

            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search by branch name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <span className="search-icon">
                  <IoSearch />
                </span>
              </div>
            </div>

            <button
              className="btn btn-addCredential"
              onClick={handleAddBranchClick}
            >
              <IoMdAdd />
              Add Branch
            </button>

          </div>

          <div className=" text-center" style={{height: '87%', }}>
            {/* <div class="TableHeaderMain mb-3">
                <h6 class="TableHeaderText">Branch List</h6>
              </div> */}
            <div class="TableHeaderText" style={{height: '9%', alignContent: 'center'}}>
              Branch List
            </div>

            <div className="table-responsive py-1" style={{height: '81%', overflowY: 'scroll', scrollbarWidth: 'none', color: '#808080', margin: '0 5px 0 5px'}}>
              <table className="mx-auto table table-striped table-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                    <th>Division Code</th>
                    <th>Division Name</th>
                    <th>Region Code</th>
                    <th>Region Name</th>
                    {/* Add this column */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentBranches.length > 0 ? (
                    currentBranches.map((branch, index) => (
                      <tr key={branch.id}>
                        <td>
                          {index + 1 + (currentPage - 1) * branchesPerPage}
                        </td>
                        <td>{branch.branchCode}</td>
                        <td>{branch.branchName}</td>
                        <td>{branch.division.divisionCode}</td>
                        <td>{branch.division.divisionName}</td>
                        <td>{branch.division.region.regionCode}</td>
                        <td>{branch.division.region.regionName}</td>

                        {/* Display branch code */}
                        <td>
                          <button
                            className="btn btn-edit"
                            title="Edit"
                            onClick={() => handleEditBranch(branch)}
                          >
                            <MdModeEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No branches found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div style={{height: '10%', display: 'flex', justifyContent: 'center',  }}>
            <Pagination className="  custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() =>
                  paginate(currentPage > 1 ? currentPage - 1 : currentPage)
                }
              />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (number) => (
                  <Pagination.Item
                    className="active"
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  paginate(
                    currentPage > 1 && currentPage < totalPages
                      ? currentPage + 1
                      : currentPage
                  )
                }
              />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
            </div>
          </div>

          </div>
        </div>
      </div>

      <AddBranchModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        fetchBranches={fetchBranches}
      />
      {selectedBranch && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editDivision">
                <Form.Label className="pt-2">Division</Form.Label>
                <Select
                  value={selectedBranch.divisionId}
                  onChange={(selectedOption) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      divisionId: selectedOption,
                    })
                  }
                  options={divisions.map((division) => ({
                    value: division.id,
                    label: division.divisionName,
                  }))}
                  isClearable
                  placeholder="---Select Division---"
                  className="w-100"
                />
              </Form.Group>
              <Form.Group controlId="editBranchCode">
                <Form.Label className="pt-2">Branch Code</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedBranch.branchCode}
                  onChange={(e) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      branchCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editBranchName">
                <Form.Label className="pt-2">Branch Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedBranch.branchName}
                  onChange={(e) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      branchName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <h6 className="text-danger">{validationError}</h6>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default AdminBranchList;

import React, { useEffect, useCallback, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";

const GenerateDocumentBranchManagerModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  proposedLoanAmount,
  sanctionedLoanAmount,
  processingCharge,
  isProcessingChargePaid,
  setIsProcessingChargePaid,
  gst,
  isGstPaid,
  setIsGstPaid,
  securityDeposit,
  isSecurityDepositPaid,
  setIsSecurityDepositPaid,
  isLoanInsured,
  setIsLoanInsured,
  insuranceAmount,
  // setInsuranceAmount,
  isInsuranceAmountPaid,
  setIsInsuranceAmountPaid,
  amountToRelease,
  setAmountToRelease,
  emiDateByBranchManager,
  setEmiDateByBranchManager,
}) => {
  const [validationError, setValidationError] = useState("");

  // const yesNoOptions = [
  //   { value: true, label: "Yes" },
  //   { value: false, label: "No" },
  // ];

  const emiOptions = Array.from({ length: 20 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString(),
  }));

  const handleClick = () => {
    if (
      (isLoanInsured && (!insuranceAmount || insuranceAmount === 0)) ||
      emiDateByBranchManager === null
    ) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm();
    }
  };

  const calculateLoanAmountToRelease = useCallback(() => {
    let releaseAmount = sanctionedLoanAmount;

    // Subtract processing charge if not paid
    if (!isProcessingChargePaid) {
      releaseAmount -= processingCharge;
    }

    // Subtract GST if not paid
    if (!isGstPaid) {
      releaseAmount -= gst;
    }

    // Subtract security deposit if not paid
    if (!isSecurityDepositPaid) {
      releaseAmount -= securityDeposit;
    }

    // Subtract insurance amount if loan is insured and insurance amount not paid
    if (isLoanInsured && !isInsuranceAmountPaid) {
      releaseAmount -= insuranceAmount;
    }

    // Ensure the release amount doesn't go negative
    releaseAmount = releaseAmount < 0 ? 0 : releaseAmount;

    setAmountToRelease(releaseAmount);
  }, [
    sanctionedLoanAmount,
    isProcessingChargePaid,
    processingCharge,
    isGstPaid,
    gst,
    isSecurityDepositPaid,
    securityDeposit,
    isLoanInsured,
    isInsuranceAmountPaid,
    insuranceAmount,
    setAmountToRelease,
  ]);

  useEffect(() => {
    calculateLoanAmountToRelease();
  }, [calculateLoanAmountToRelease]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <div className="my-3">
          <Form.Group>
            <Form.Label>Proposed Loan Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Proposed Loan Amount"
              value={proposedLoanAmount}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Sanctioned Loan Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Sanctioned Loan Amount"
              value={sanctionedLoanAmount}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Processing Charge (2.5 % loan amount)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Processing Charge"
              value={processingCharge}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Have you Collected Processing Charge</Form.Label>
            <Select
              options={yesNoOptions}
              onChange={(selectedOption) => {
                setIsProcessingChargePaid(selectedOption.value);
              }}
              value={yesNoOptions.find(
                (option) => option.value === isProcessingChargePaid
              )}
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>GST (18 % processing charge)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter GST"
              value={gst}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Have you Collected GST Amount</Form.Label>
            <Select
              options={yesNoOptions}
              onChange={(selectedOption) => {
                setIsGstPaid(selectedOption.value);
              }}
              value={yesNoOptions.find((option) => option.value === isGstPaid)}
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Security Deposit (1 month emi amount)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Security Deposit"
              value={securityDeposit}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Have you Collected Security Deposit</Form.Label>
            <Select
              options={yesNoOptions}
              onChange={(selectedOption) => {
                setIsSecurityDepositPaid(selectedOption.value);
              }}
              value={yesNoOptions.find(
                (option) => option.value === isSecurityDepositPaid
              )}
            />
          </Form.Group> */}
          {/* <Form.Group>
            <Form.Label>Loan Insured?</Form.Label>
            <Select
              options={yesNoOptions}
              onChange={(selectedOption) => {
                setIsLoanInsured(selectedOption.value);
              }}
              value={yesNoOptions.find(
                (option) => option.value === isLoanInsured
              )}
            />
          </Form.Group> */}
          {isLoanInsured && (
            <>
              <Form.Group>
                <Form.Label>Insurance Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Insurance Amount"
                  value={insuranceAmount}
                  readOnly
                  // onChange={(event) => {
                  //   setInsuranceAmount(event.target.value);
                  // }}
                  // required
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Have you Collected Insurance Amount</Form.Label>
                <Select
                  options={yesNoOptions}
                  onChange={(selectedOption) => {
                    setIsInsuranceAmountPaid(selectedOption.value);
                  }}
                  value={yesNoOptions.find(
                    (option) => option.value === isInsuranceAmountPaid
                  )}
                />
              </Form.Group> */}
            </>
          )}
          <Form.Group>
            <Form.Label>Loan Amount To Release</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Loan Amount To Release"
              value={amountToRelease}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>EMI Date</Form.Label>
            <Select
              options={emiOptions}
              onChange={(selectedOption) => {
                setEmiDateByBranchManager(selectedOption.value);
              }}
              value={emiOptions.find(
                (option) => option.value === emiDateByBranchManager
              )}
            />
          </Form.Group>
          <p className="text-danger">{validationError}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Generate Document
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateDocumentBranchManagerModal;

import React from "react";
import { RiLogoutCircleRLine } from "react-icons/ri";
import "../screens/AdminDashboard.css";
import { BsJustify } from "react-icons/bs";
import { useAdminAuth } from "./AdminAuthProvider";
import { useNavigate } from "react-router-dom";

const DashboardHeader = ({ OpenSidebar }) => {
  const { adminLogout } = useAdminAuth();
  const navigate = useNavigate();
  const handleLogoutClick = () => {
    adminLogout();
    navigate("/");
  };

  return (
    <section style={{ height: "100%" }}>
      <nav
        className="custom-navbar navbar navbar-expand-md  py-lg-3 px-3 "
        style={{ height: "100%", width: "100%", display: "flex" }}
      >
        <div className="container-fluid py-2 px-3 " style={{backgroundColor: '#212121', borderRadius: '20px'}}>
          <div className="menu-icon">
            <BsJustify className="icon" style={{ fontSize: '18px'}} onClick={OpenSidebar} />
          </div>
          <div className="navbar-brand ">VETRI VIKAS CAPITAL TRUST</div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-icon"></span>
          </button>
          <div>
            <button
              className="btn btn-logout "
              title="Logout"
              onClick={handleLogoutClick}
            >
              <RiLogoutCircleRLine />
            </button>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default DashboardHeader;

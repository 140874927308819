import React from "react";
import { BASE_URL } from "../env";
import "../screens/MemberPrint.css";
import { convertDateStringToDMY, formatISODateToLocalDMY } from "./utils";

function getNestedValue(obj, path, defaultValue = "-") {
  // Handle custom calculation for totalChargesDeducted
  if (path === "totalChargesDeducted") {
    const processingCharge = obj?.memberDetails?.processingCharge || 0;
    const gst = obj?.memberDetails?.gst || 0;
    const securityDeposit = obj?.memberDetails?.securityDeposit || 0;
    const insuranceAmount = obj?.memberDetails?.insuranceAmount || 0;

    // Perform the calculation and return the total
    return processingCharge + +gst + securityDeposit + insuranceAmount;
  }
  if (path === "insurancePremium") {
    return obj?.memberDetails?.insuranceAmount || defaultValue;
  }
  if (
    path === "tentativeForeClosureAmountOfExistingLoan" ||
    path === "foreClosureCharges"
  ) {
    return "4%";
  }
  if (path === "latePayment" || path === "emiPickUpOrCollectionCharges") {
    return "250";
  }
  if (path === "checkBounceCharges") {
    return "450";
  }
  if (path === "repaymentMode" || path === "repaymentFrequency") {
    return "monthly";
  }
  if (path === "foreClosureNotAllowedBeforeLockInPeriod") {
    return "4% for outstanding amount";
  }
  if (path === "chequeOrEcsSwipeCharges") {
    return "500";
  }
  if (path === "accountType") {
    return (
      <div>
        <label>
          <input type="checkbox" /> Saving
        </label>
        <label style={{ marginLeft: "10px" }}>
          <input type="checkbox" /> Current
        </label>
        <label style={{ marginLeft: "10px" }}>
          <input type="checkbox" /> OD
        </label>
      </div>
    );
  }

  // Default behavior for accessing nested values
  return (
    path
      .split(".")
      .reduce(
        (acc, part) => (acc && acc[part] !== undefined ? acc[part] : undefined),
        obj
      ) || defaultValue
  );
}

export const DetailTable = ({ details, fields }) => {
  const rows = [];

  for (let i = 0; i < fields.length; i += 3) {
    rows.push(fields.slice(i, i + 3));
  }

  return (
    <table className="details-table">
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map(({ key, label }) => {
              let value = details[key];

              // Format the dateOfBirth field
              if (key === "dateOfBirth" && value) {
                value = convertDateStringToDMY(value);
              }
              if (key === "salaryDate" && value) {
                value = formatISODateToLocalDMY(value);
              }

              if (value === null || value === undefined) {
                value = "";
              }
              return (
                <>
                  <td key={`label-${key}`} className="key-container">
                    {label}
                  </td>
                  <td key={`value-${key}`} className="key-value-container">
                    {value}
                  </td>
                </>
                // <td key={key}>
                //   <div style={{ display: "flex" }}>
                //     <div className="label-container">
                //       <p>{label}</p>
                //     </div>
                //     <div className="value-container">
                //       <p>{value}</p>
                //     </div>
                //   </div>
                // </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const PhotoTable = ({ details, fields }) => {
  return (
    <table className="phototableborder">
      <tbody>
        {fields.map(({ key, label }) => (
          <tr key={key}>
            <td>
              <div className="phototable">
                {renderImage(details[key])} <p className="ptolabel">{label}</p>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const renderImage = (imageName) => {
  return imageName ? (
    <img
      src={`${BASE_URL}/images/${imageName}`}
      alt=""
      style={{
        width: "100px",
        height: "100px",

        objectFit: "cover",
      }}
    />
  ) : (
    <p></p>
  );
};

export const NomineePhotoTable = ({ details, fields }) => {
  return (
    <table className="phototableborder">
      <tbody>
        {fields.map(({ key, label }) => (
          <tr key={key}>
            <td>
              <div className="phototable">
                {renderImage(details[key])} <p className="ptolabel"> {label}</p>{" "}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const SanctionLetterTable = ({ details, fields }) => {
  const rows = [];

  for (let i = 0; i < fields.length; i += 2) {
    rows.push(fields.slice(i, i + 2));
  }

  // console.log("rows: " + JSON.stringify(rows));
  // console.log("details: " + JSON.stringify(details));

  return (
    <table
      className="table"
      style={{
        width: "100%",
        borderCollapse: "collapse",
        border: "2px solid black",
      }}
    >
      <thead>
        <tr className="section">
          <th
            className="text-center"
            style={{
              border: "2px solid black",
              width: "50%",
              fontSize: "22px",
            }}
            colSpan={2}
          >
            Loan Details / கடன் விபரங்கள்
          </th>
          <th
            className="text-center"
            style={{
              border: "2px solid black",
              width: "50%",
              fontSize: "22px",
            }}
            colSpan={2}
          >
            Rate and Charge / விகிதம் மற்றும் கட்டணங்கள்
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className="section">
            <td
              style={{
                border: "2px solid black",
                width: "25%",
                fontSize: "26px",
              }}
            >
              {row[0].label} <br />
              {row[0].tamillabel}
            </td>
            <td
              style={{
                border: "2px solid black",
                width: "25%",
                fontSize: "30px",
              }}
            >
              {getNestedValue(details, row[0]?.key)}
            </td>
            <td
              style={{
                border: "2px solid black",
                width: "25%",
                fontSize: "26px",
              }}
            >
              <span style={{ fontSize: "22px" }}>{row[1] && row[1].label}</span>{" "}
              <br />
              {row[1] && row[1].tamillabel}
            </td>
            <td
              style={{
                border: "2px solid black",
                width: "25%",
                fontSize: "30px",
              }}
            >
              {row[1] && getNestedValue(details, row[1]?.key)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export const DisbursementFormTable = ({ details, fields }) => {
  const rows = [];

  for (let i = 0; i < fields.length; i += 1) {
    rows.push(fields.slice(i, i + 1));
  }

  return (
    <table
      className="table"
      style={{
        width: "100%",
        borderCollapse: "collapse",
        border: "2px solid black",
      }}
    >
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td
              style={{
                border: "2px solid black",
                width: "50%",
                fontSize: "26px",
              }}
            >
              <span style={{ fontSize: "26px" }}> {row[0].label}</span> -{" "}
              {row[0].tamillabel}
            </td>
            <td
              style={{
                border: "2px solid black",
                width: "50%",
                fontSize: "30px",
              }}
            >
              {getNestedValue(details, row[0]?.key)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const memberFields = [
  { key: "title", label: "Title" },
  { key: "memberName", label: "Name" },
  { key: "gender", label: "Gender" },
  { key: "dateOfBirth", label: "Date Of Birth" },
  { key: "age", label: "Age" },
  { key: "phoneNumber", label: "Phone No" },
  { key: "aadharNo", label: "Aadhar No" },
  { key: "panNo", label: "Pan No" },
  { key: "anotherIdentity", label: "Another Identity" },
  { key: "anotherIdentityId", label: "Another Identity Id" },
  { key: "ebNumber", label: "EB Number" },
  { key: "familyCardNo", label: "Family Card No" },
  { key: "rationShopNo", label: "Ration Shop No" },
  { key: "emiDateByBranchManager", label: "EMI Date" },
  { key: "ApplicationId", label: "Application ID" },
];

export const businessFields = [
  { key: "sourceOfIncome", label: "Source Of Income" },
  { key: "shopName", label: "Shop Name" },
  { key: "businessType", label: "Business Type" },
  { key: "natureOfBusiness", label: "Nature Of Business" },
  { key: "shopAddress", label: "Shop Address" },
  { key: "shopLocation", label: "Shop Location" },
  { key: "overallBusinessExperience", label: "over All Business Experience" },
  { key: "stabilityInBusinessInYears", label: "Stability In Years" },
  { key: "stabilityInBusinessInMonths", label: "Stability In Months" },
  {
    key: "yearsOfStabilityInCurrentAddress",
    label: " Stability In Current Address (yrs)",
  },
  { key: "monthlyBusinessIncome", label: "Monthly Business Income" },
  { key: "gstNumber", label: "GST Number" },
  { key: "udhayamNumber", label: "Udhayam Number" },
  { key: "district", label: "District" },
  { key: "state", label: "State" },
  { key: "nationality", label: "Nationality" },
];

export const residenceFields = [
  { key: "residenceType", label: "Residence Type" },
  { key: "stayingStabilityInYears", label: "Staying Stability (Years)" },
  { key: "stayingStabilityInMonths", label: "Staying Stability (Months)" },
  { key: "currentDoorNo", label: " Door No" },
  { key: "currentStreetNo", label: " Street No" },
  { key: "currentPanchayat", label: " Panchayat" },
  { key: "currentTaluk", label: " Taluk" },
  { key: "currentDistrict", label: " District" },
  { key: "currentPincode", label: " Pincode" },
  { key: "state", label: "State" },
  { key: "nationality", label: "Nationality" },
];

export const permanentResidenceFields = [
  { key: "permanentDoorNo", label: "Door No" },
  { key: "permanentStreetNo", label: "Street No" },
  { key: "permanentPanchayat", label: " Panchayat" },
  { key: "permanentTaluk", label: "Taluk" },
  { key: "permanentDistrict", label: "District" },
  { key: "permanentPincode", label: "Pincode" },
];

export const CoApplicantPersonalFields = [
  { key: "title", label: "Title" },
  { key: "coApplicantName", label: "Name" },
  { key: "gender", label: "Gender" },
  { key: "dateOfBirth", label: "Date Of Birth" },
  { key: "age", label: "Age" },
  { key: "phoneNumber", label: "Phone No" },
  { key: "aadharNo", label: "Aadhar No" },
  { key: "panNo", label: "Pan No" },
  { key: "anotherIdentity", label: "Another Identity" },
  { key: "anotherIdentityId", label: "Another Identity Id" },
  { key: "ebNumber", label: "EB Number" },
  { key: "familyCardNo", label: "Family Card No" },
  { key: "rationShopNo", label: "Ration Shop No" },
];

export const coApplicantBusinessFields = [
  { key: "name", label: "Name" },
  { key: "sourceOfIncome", label: "Source Of Income" },
  { key: "designation", label: "Designation" },
  {
    key: "experienceInCurrentCompanyInYears",
    label: "Experience In Current Company (Years)",
  },
  {
    key: "experienceInCurrentCompanyInMonths",
    label: "Experience In Current Company (Months)",
  },
  { key: "companyName", label: "Company Name" },
  { key: "companyAddress", label: "Company Address" },
  { key: "overallExperienceInYears", label: "Overall Experience (Years)" },
  { key: "overallExperienceInMonths", label: "Overall Experience (Months)" },
  { key: "monthlySalary", label: "Monthly Salary" },
  { key: "salaryDate", label: "Salary Date" },
  { key: "shopName", label: "Shop Name" },
  { key: "shopAddress", label: "Shop Address" },
  { key: "shopLocation", label: "Shop Location" },
  { key: "businessType", label: "Business Type" },
  { key: "natureOfBusiness", label: "Nature Of Business" },
  {
    key: "stabilityInBusinessInYears",
    label: "Stability In Business (Years)",
  },
  {
    key: "stabilityInBusinessInMonths",
    label: "Stability In Business (Months)",
  },
  {
    key: "yearsOfStabilityInCurrentAddress",
    label: "Years Of Stability In Current Address",
  },
  { key: "monthlyBusinessIncome", label: "Monthly Business Income" },
  { key: "gstNumber", label: "GST Number" },
  { key: "isUdhayamRegistered", label: "Udhayam Registered" },
  { key: "udhayamNumber", label: "Udhayam Number" },
  { key: "district", label: "District" },
  { key: "state", label: "State" },
  { key: "nationality", label: "Nationality" },
  { key: "overallBusinessExperience", label: "Overall Business Experience" },
];

export const coApplicantResidenceFields = [
  { key: "residenceType", label: "Residence Type" },
  { key: "stayingStabilityInYears", label: "Staying Stability (Years)" },
  { key: "stayingStabilityInMonths", label: "Staying Stability (Months)" },
  { key: "currentDoorNo", label: " Door No" },
  { key: "currentStreetNo", label: " Street No" },
  { key: "currentPanchayat", label: " Panchayat" },
  { key: "currentTaluk", label: " Taluk" },
  { key: "currentDistrict", label: " District" },
  { key: "currentPincode", label: " Pincode" },
  { key: "state", label: "State" },
  { key: "nationality", label: "Nationality" },
];

export const coApplicantPermanentResidenceFields = [
  { key: "permanentDoorNo", label: "Door No" },
  { key: "permanentStreetNo", label: "Street No" },
  { key: "permanentPanchayat", label: " Panchayat" },
  { key: "permanentTaluk", label: "Taluk" },
  { key: "permanentDistrict", label: "District" },
  { key: "permanentPincode", label: "Pincode" },
];

export const loanDetailsFields = [
  { key: "totalloanAmount", label: "Total Loan Amount" },
  { key: "currentNoOfLoans", label: "Current No. of Loans" },
  { key: "loanCompanyNames", label: "Loan Company Names" },
  { key: "totalmonthlyEmi", label: "Total Monthly EMI" },
];

export const proposedLoanDetailsFields = [
  { key: "emiDate", label: "EMI Date" },
  { key: "loanAmount", label: "Loan Amount" },
  { key: "monthlyEmi", label: "Monthly EMI" },
  { key: "rateOfInterest", label: "Rate of Interest (%)" },
  { key: "tenureInMonths", label: "Tenure (Months)" },
  { key: "houseHoldExpenses", label: "Household Expenses" },
  { key: "otherExpenses", label: "Other Expenses" },
];

export const bankDetailsFields = [
  { key: "bankName", label: "Bank Name" },
  { key: "customerName", label: "Customer Name" },
  { key: "accountNumber", label: "Account Number" },
  { key: "ifscCode", label: "IFSC Code" },
  { key: "bankBranch", label: "Bank Branch" },
];

export const sanctionLetterFields = [
  {
    key: "memberDetails.memberName",
    label: "Name of Applicant ",
    tamillabel: "விண்ணப்பதாரரின் பெயர்",
  },
  {
    key: "proposedLoanDetails.rateOfInterest",
    label: "Fixed ROI ",
    tamillabel: "நிலையான ஆர்ஒஐ",
  },

  {
    key: "coApplicantDetails.coApplicantName",
    label: "Name of Co-Applicant  - 1 ",
    tamillabel: "இணை விண்ணப்பதாரரின் பெயர் - 1",
  },

  {
    key: "memberDetails.sanctionedLoanAmountBySanctionCommittee",
    label: "Loan Amount (A)",
    tamillabel: "கடன் தொகை (அ)",
  },

  {
    key: "memberDetails.processingCharge",
    label: "Loan Documentation and Enrolment Charges",
    tamillabel: "கடன் ஆவணம் மற்றும் சேர்க்கை கட்டணங்கள்",
  },
  {
    key: "memberDetails.ApplicationId",
    label: "Loan Account Number",
    tamillabel: "கடன் கணக்கு எண்",
  },
  {
    key: "memberDetails.gst",
    label: "GST on above ",
    tamillabel: "மேற்குறிப்பிட்டவைகளுக்கான ஜிஎஸ்டி",
  },
  {
    key: "insurancePremium",
    label: "Insurance Premium",
    tamillabel: "காப்பீட்டு பிரீமியம்",
  },
  {
    key: "proposedLoanDetails.tenureInMonths",
    label: "No.of EMIs",
    tamillabel: "இஎம்ஐ களின் எண்ணிக்கை",
  },
  {
    key: "N/A",
    label: "General Insurance Premium ",
    tamillabel: "பொதுக்காப்பீட்டு பிரீமியம்",
  },
  {
    key: "memberDetails.securityDeposit",
    label: "Tentative EMI Amount ",
    tamillabel: "தற்காலிக இஎம்ஐ தொகை",
  },
  {
    key: "memberDetails.securityDeposit",
    label: "Security Deposit",
    tamillabel: "காப்பு தொகைs",
  },
  {
    key: "businessDetails.natureOfBusiness",
    label: "Loan purpose / end use",
    tamillabel: "கடன் நோக்கம் / இறுதி பயன்பாடு",
  },
  {
    key: "totalChargesDeducted",
    label: "Total charges deducted (B)",
    tamillabel: "கழிக்கப்பட்ட மொத்த கட்டணங்கள் (M)",
  },
  {
    key: "N/A",
    label: "Other Incidental Charges",
    tamillabel: "பிற தற்செயல் கட்டணங்கள்",
  },
  {
    key: "tentativeForeClosureAmountOfExistingLoan",
    label: "** Tentative foreclosure amount of existing loan (C) ** ",
    tamillabel: "நடப்பு லோனை தற்காலிகமாக முன் கூட்டியே முடித்தல் (இ)",
  },
  // newwww
  {
    key: "latePayment",
    label:
      "Late payment / penal charges / default interest / overdue (per month) ",
    tamillabel:
      "தாமதமாக பணம் செலுத்துதல் / அபராதக் கட்டணங்கள் / செலுத்தத் தவறிய பணத்துக்கான வட்டி / காலம் கடந்த கட்டணம் (மாதம் ஒன்றுக்கு)",
  },

  {
    key: "memberDetails.amountToRelease",
    label: "*Net disbursement amount (A-B-C)",
    tamillabel: "* நிகர விநியோக தொகை (அ-ஆ-இ)",
  },
  {
    key: "checkBounceCharges",
    label: "Cheque Bounce Charges ",
    tamillabel: "செல்லுபடியாகாத காசோலைகளுக்கான கட்டணங்கள்",
  },

  {
    key: "repaymentMode",
    label: "Repayment Mode",
    tamillabel: "திருப்பிச் செலுத்தும் முறை",
  },
  {
    key: "emiPickUpOrCollectionCharges",
    label: "EMI Pickup/Collection Charges ",
    tamillabel: "EMI பிக்அப் /கலெக்சன் கட்டணங்கள்",
  },

  {
    key: "foreClosureCharges",
    label: "Foreclosure Charges (wherever Applicable)",
    tamillabel: "முன்கூட்டியே கடனைச் செலுத்தி முடிப்பதற்கான கட்டணங்கள்",
  },
  {
    key: "N/A",
    label: "Moratorium",
    tamillabel: "கடனை காலந்தாழ்த்தி கொடுப்பதற்கான சட்ட இசைவு",
  },

  {
    key: "N/A",
    label: "Moratorium Repayment Due Date",
    tamillabel: "கடன் காலந்தாழ்த்தி திருப்பிக் கொடுப்பதற்கான உரிய தேதி",
  },
  {
    key: "foreClosureNotAllowedBeforeLockInPeriod",
    label: "Foreclosure not allowed before lock-in period",
    tamillabel:
      "லாக்-இன் காலத்துக்கு முன்னதாக கடனை திருப்பிச் செலுத்த அனுமதி கிடையாது",
  },
  {
    key: "memberDetails.emiDateByBranchManager",
    label: "EMI due date",
    tamillabel: "செலுத்த வேண்டிய தேதி",
  },
  { key: "N/A", label: "PMJ JBY Insurance Premium", tamillabel: "" },
  { key: "N/A", label: "PMS BY Insurance Premium", tamillabel: "" },
  {
    key: "chequeOrEcsSwipeCharges",
    label: "CHEQUE/ECS SWIPE CHARGES",
    tamillabel: "காசோலை / இ.சி.எஸ். ஸ்வைப் கட்டணங்கள்",
  },
  {
    key: "repaymentFrequency",
    label: "Repayment Frequency",
    tamillabel: "திருப்பிச் செலுத்தும் கால இடைவெளி",
  },
];

export const disbursementFormFields = [
  {
    key: "bankDetails.customerName",
    label: "Name of the Account Holder (as per bank)",
    tamillabel: "கணக்குதாரர் பெயர் (வங்கியில் கொடுக்கப்பட்ட பெயர்)",
  },
  {
    key: "bankDetails.bankName",
    label: "Name of the Bank ",
    tamillabel: "வங்கியின் பெயர்",
  },

  {
    key: "bankDetails.bankBranch",
    label: "Branch Name",
    tamillabel: "கிளை பெயர்",
  },

  {
    key: "bankDetails.accountNumber",
    label: "Account Number",
    tamillabel: "கணக்கு எண்",
  },
  { key: "accountType", label: "ACCOUNT TYPE", tamillabel: "கணக்கு வகை" },
  {
    key: "bankDetails.ifscCode",
    label: "IFSC Code ",
    tamillabel: "ஐ எ ப் எஸ் சி குறியீடு",
  },
];

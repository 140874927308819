import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import AddRegionModal from "./AddRegionModal";
import { IoMdAdd } from "react-icons/io";

const AdminRegionList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regions, setRegions] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [regionsPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({
    id: null,
    regionName: "",
    regionCode: "",
  });

  const fetchRegions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/regions`);

      setRegions(response.data);
      setFilteredRegions(response.data);
      // console.log("RegionList: " + JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    const filtered = regions.filter((region) =>
      region.regionName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRegions(filtered);
    setCurrentPage(1);
  }, [searchQuery, regions]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddRegionClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditRegion = (region) => {
    setSelectedRegion(region);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedRegion(null);
  };

  const handleSaveChanges = async () => {
    if (!selectedRegion.regionCode || !selectedRegion.regionName) {
      setValidationError("Please Enter All Fields");
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}/api/admin/editregion`, {
        regionId: selectedRegion.id,
        regionName: selectedRegion.regionName,
        regionCode: selectedRegion.regionCode,
      });
      if (response.status === 200) {
        fetchRegions();
        handleCloseEditModal();
      } else {
        setValidationError("Error: " + response.data.error);
        console.error("Failed to update region details");
      }
    } catch (error) {
      console.error("Error updating region details:", error);
    }
  };

  const indexOfLastRegion = currentPage * regionsPerPage;
  const indexOfFirstRegion = indexOfLastRegion - regionsPerPage;
  const currentRegions = filteredRegions.slice(
    indexOfFirstRegion,
    indexOfLastRegion
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredRegions.length / regionsPerPage);

  return (
    <section>
      <div className="d-flex">
        <div style={{ width: "14%" }}>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>

        <div
          className=""
          style={{
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: "40px 0px 0px 0px",
              backgroundColor: "white",
            }}
          >
            <div className="search-container" style={{ height: "13%" }}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    placeholder="Search by region name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                  />
                  <span className="search-icon">
                    <IoSearch />
                  </span>
                </div>
              </div>

              <button
                className="btn btn-addCredential"
                onClick={handleAddRegionClick}
              >
                <IoMdAdd />
                Add Region
              </button>
            </div>

            <div className=" text-center" style={{ height: "87%" }}>
              {/* <div class="TableHeaderMain mb-3">
                <h6 class="TableHeaderText">Branch List</h6>
              </div> */}
              <div
                class="TableHeaderText"
                style={{ height: "9%", alignContent: "center" }}
              >
                Region List
              </div>
              <div
                className="table-responsive py-1"
                style={{
                  height: "81%",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  color: "#808080",
                  margin: '0 5px 0 5px'
                }}
              >
                <table className="mx-auto table table-striped table-list">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Region Code</th>
                      <th>Region Name</th>
                      {/* Add this column */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRegions.length > 0 ? (
                      currentRegions.map((region, index) => (
                        <tr key={region.id}>
                          <td>
                            {index + 1 + (currentPage - 1) * regionsPerPage}
                          </td>
                          <td>{region.regionCode}</td>
                          <td>{region.regionName}</td>

                          {/* Display branch code */}
                          <td>
                            <button
                              className="btn btn-edit"
                              title="Edit"
                              onClick={() => handleEditRegion(region)}
                            >
                              <MdModeEdit />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No regions found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination className=" custom-pagination">
                  <Pagination.First onClick={() => paginate(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      paginate(currentPage > 1 ? currentPage - 1 : currentPage)
                    }
                  />
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (number) => (
                      <Pagination.Item
                        className="active"
                        key={number}
                        active={number === currentPage}
                        onClick={() => paginate(number)}
                      >
                        {number}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() =>
                      paginate(
                        currentPage > 1 && currentPage < totalPages
                          ? currentPage + 1
                          : currentPage
                      )
                    }
                  />
                  <Pagination.Last onClick={() => paginate(totalPages)} />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddRegionModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        fetchRegions={fetchRegions}
      />
      {selectedRegion && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Region</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editRegionCode">
                <Form.Label className="pt-2">Region Code</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedRegion.regionCode}
                  onChange={(e) =>
                    setSelectedRegion({
                      ...selectedRegion,
                      regionCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editRegionName">
                <Form.Label className="pt-2">Region Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedRegion.regionName}
                  onChange={(e) =>
                    setSelectedRegion({
                      ...selectedRegion,
                      regionName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <h6 className="text-danger">{validationError}</h6>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default AdminRegionList;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { InputGroup, FormControl, Button, Row, Col } from "react-bootstrap";
import MemberDetailsModal from "../components/MemberDetailsModal";
import { IoSearch, IoDocumentTextOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import ActionButtons from "../components/ActionButtons";
import Zoom from "react-medium-image-zoom";
import { MdContentPasteSearch, MdDateRange } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaAngleRight, FaUser } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { FiSearch } from "react-icons/fi";
import { GrFormNext, GrFormNextLink } from "react-icons/gr";

const MemberDetails = () => {
  const location = useLocation();
  const { filterStatus } = location.state || {};
  const { adminUser } = useAdminAuth();

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedCros, setSelectedCros] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);

  // Extract unique branches for the select options
  const branchOptions = [
    ...new Set(memberData.map((member) => member.branchName)),
  ].map((branch) => ({
    label: branch,
    value: branch,
  }));

  const croOptions = [
    ...new Set(memberData.map((member) => member.croName)),
  ].map((cro) => ({
    label: cro,
    value: cro,
  }));

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const fetchMemberDetails = useCallback(async () => {
    try {
      const params = {};
      // if (filterStatus === "rejected") {
      //   params.sanctionCommitteeStatus = "rejected";
      // }
      let getMemberDetails = "";
      if (adminUser.userType === "Branch Manager") {
        getMemberDetails = "getMemberDetailsByBranchManager";
        params.branchManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.branchManagerStatus = ["submitted", "disbursed"];
        }
        if (filterStatus === "pending") {
          params.branchManagerStatus = "pending";
          params.fieldManagerStatus = "submitted";
        }
        if (filterStatus === "retrigger") {
          params.branchManagerStatus = "retrigger";
        }
        if (filterStatus === "disbursed") {
          params.fieldManagerStatus = "submitted";
          params.branchManagerStatus = "submitted";
          params.creditOfficerStatus = "submitted";
          params.misStatus = "submitted";
          params.creditManagerStatus = "submitted";
          params.sanctionCommitteeStatus = "approved";
          params.accountManagerStatus = "pending";
        }
        if (filterStatus === "rejected") {
          params.branchManagerStatus = "rejected";
        }
      }
      if (adminUser.userType === "Credit Officer") {
        getMemberDetails = "getMemberDetailsByCreditOfficer";
        params.creditOfficerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.creditOfficerStatus = "submitted";
        }
        if (filterStatus === "pending") {
          params.creditOfficerStatus = "pending";
          params.branchManagerStatus = "submitted";
        }
        if (filterStatus === "retrigger") {
          params.creditOfficerStatus = "retrigger";
        }
        if (filterStatus === "rejected") {
          params.creditOfficerStatus = "rejected";
        }
      }
      if (adminUser.userType === "MIS") {
        getMemberDetails = "getMemberDetailsByMis";
        params.misId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.misStatus = ["submitted", "disbursed"];
        }
        if (filterStatus === "pending") {
          params.misStatus = "pending";
          params.creditOfficerStatus = "submitted";
        }
        if (filterStatus === "retrigger") {
          params.misStatus = "retrigger";
        }
        if (filterStatus === "disbursed") {
          params.fieldManagerStatus = "submitted";
          params.branchManagerStatus = "disbursed";
          params.creditOfficerStatus = "submitted";
          params.misStatus = "submitted";
          params.creditManagerStatus = "submitted";
          params.sanctionCommitteeStatus = "approved";
          params.accountManagerStatus = "pending";
        }
        if (filterStatus === "rejected") {
          params.misStatus = "rejected";
        }
      }
      if (adminUser.userType === "Credit Manager") {
        getMemberDetails = "getMemberDetailsByCreditManager";
        params.creditManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.creditManagerStatus = "submitted";
        }
        if (filterStatus === "pending") {
          params.creditManagerStatus = "pending";
          params.misStatus = "submitted";
        }
        if (filterStatus === "retrigger") {
          params.creditManagerStatus = "retrigger";
        }
        if (filterStatus === "rejected") {
          params.creditManagerStatus = "rejected";
        }
      }
      if (adminUser.userType === "Sanction Committee") {
        getMemberDetails = "getMemberDetailsBySanctionCommittee";
        params.sanctionCommitteeId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.sanctionCommitteeStatus = "approved";
        }
        if (filterStatus === "pending") {
          params.sanctionCommitteeStatus = "pending";
          params.creditManagerStatus = "submitted";
        }
        if (filterStatus === "retrigger") {
          params.sanctionCommitteeStatus = "retrigger";
        }
        if (filterStatus === "rejected") {
          params.sanctionCommitteeStatus = "rejected";
        }
      }
      if (adminUser.userType === "Accounts Manager") {
        getMemberDetails = "getMemberDetailsByAccountsManager";
        params.accountManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.accountManagerStatus = "payment credited";
        }
        if (filterStatus === "pending") {
          params.accountManagerStatus = "pending";
          params.misStatus = "disbursed";
        }
        if (filterStatus === "retrigger") {
          params.accountManagerStatus = "retrigger";
        }
      }
      if (
        adminUser.userType === "superadmin" ||
        adminUser.userType === "developer"
      ) {
        getMemberDetails = "getMemberDetailsBySuperadmin";
        params.superadminId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.accountManagerStatus = "payment credited";
        }
      }
      const response = await axios.get(`${BASE_URL}/api/${getMemberDetails}`, {
        params,
      });
      // console.log("Response: " + JSON.stringify(response.data.list));
      if (
        (adminUser.userType === "superadmin" ||
          adminUser.userType === "developer") &&
        filterStatus === "rejected"
      ) {
        const filteredList = response.data.list.filter((member) =>
          [
            "branchManagerStatus",
            "creditOfficerStatus",
            "misStatus",
            "creditManagerStatus",
            "sanctionCommitteeStatus",
          ].some((status) => member[status] === "rejected")
        );
        setMemberData(filteredList);
      } else if (
        (adminUser.userType === "superadmin" ||
          adminUser.userType === "developer") &&
        filterStatus === "pending"
      ) {
        const filteredList = response.data.list.filter(
          (member) =>
            member.accountManagerStatus === "pending" &&
            [
              "branchManagerStatus",
              "creditOfficerStatus",
              "misStatus",
              "creditManagerStatus",
              "sanctionCommitteeStatus",
            ].every((status) => member[status] !== "rejected")
        );
        setMemberData(filteredList);
      } else {
        setMemberData(response.data.list);
      }
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  }, [adminUser.user.id, adminUser.userType, filterStatus]);

  useEffect(() => {
    fetchMemberDetails();
  }, [fetchMemberDetails]);

  const filteredMembers = memberData.filter((member) => {
    // Normalize the searchTerm
    const normalizedSearchTerm = searchTerm.toLowerCase();

    // Parse `fromDate` and `toDate` into comparable Date objects
    const fromDateObj = fromDate ? new Date(fromDate) : null;
    const toDateObj = toDate ? new Date(toDate) : null;

    // Check if the searchTerm matches text fields
    const matchesTextFields =
      member.memberName.toLowerCase().includes(normalizedSearchTerm) ||
      member.ApplicationId.toLowerCase().includes(normalizedSearchTerm);

    // Check if the memberCreatedAt falls within the date range
    const createdAtDateObj = new Date(
      member.createdAt // Raw date for comparison
    );

    // Normalize both createdAt and toDate to only consider the date portion (ignore time)
    const isSameDateOrBefore = (date1, date2) => {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    };

    const matchesDateRange =
      (!fromDateObj || createdAtDateObj >= fromDateObj) &&
      (!toDateObj ||
        createdAtDateObj <= toDateObj ||
        isSameDateOrBefore(createdAtDateObj, toDateObj));

    // Check if the member's branch matches the selected branches
    const matchesBranch =
      selectedBranches.length === 0 ||
      selectedBranches.some((branch) => branch.value === member.branchName);

    // Check if the member's croName matches the selected cro's
    const matchesCro =
      selectedCros.length === 0 ||
      selectedCros.some((cro) => cro.value === member.croName);

    // Return true if text fields, date range,branches and cro's match
    return matchesTextFields && matchesDateRange && matchesBranch && matchesCro;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleShowModal = (memberId) => {
    setSelectedMemberId(memberId);
    setShowModal(true);
  };

  const handleDelMember = (memberId) => {
    console.log("setSelectedMemberId: " + memberId);
    setSelectedMemberId(memberId);
    setShowDelModal(true);
  };

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked ");
        console.log("Selected memberId: " + selectedMemberId);
        try {
          const res = await axios.post(`${BASE_URL}/api/delMember`, {
            memberId: selectedMemberId,
          });
          if (res.data.message) {
            console.log("Deleted Successfully: " + selectedMemberId);
          } else if (res.data.error) {
            console.log("error from api: " + res.data.error);
          }
        } catch (error) {
          console.log("error in catch: " + error);
        } finally {
          fetchMemberDetails();
          setShowDelModal(false);
        }
      } else if (buttonValue === "No") {
        console.log("No button clicked ");
        setShowDelModal(false);
      }
    },
    [selectedMemberId, fetchMemberDetails]
  );

  return (
    <div className="dashboard-over" >
      <div className="d-flex">
        <div>
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </div>
        <div
          className=""
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
            backgroundColor: '#181818',
          }}
        >
          <div style={{ height: '14%', }}>

            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div className="member-details " style={{ height: '86%', borderRadius: '40px 0px 0px 0px', backgroundColor: 'white', }}>
            <div style={{ display: 'flex', padding: '8px 18px', alignItems: 'center',  width: '100%', height: '14%', position: 'relative',}}>
              {/* <Row style={{display: 'flex', alignItems: 'center', height: '100%',}}> */}

<div style={{ display: 'flex', alignItems: 'center', gap: '8px', position: 'absolute', zIndex: '5', width: '97%',}}>
  
<div className="input-fields">
                <input type="date" style={{ border: 'none', opacity: '0.7', }} value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)} />
              </div>

              

              <div className="input-fields">
                <input type="date" style={{ border: 'none', opacity: '0.7', }} value={toDate}
                  onChange={(e) => setToDate(e.target.value)} />
              </div>

              <Col lg={2} xs={12}>
                <Select
                  isMulti
                  options={branchOptions}
                  placeholder="Select Branches"
                  value={selectedBranches}
                  onChange={setSelectedBranches}
                  className=""
                />
              </Col>
              <Col lg={2} xs={12}>
                <Select
                  isMulti
                  options={croOptions}
                  placeholder="Select Cro's"
                  value={selectedCros}
                  onChange={setSelectedCros}
                  className=""
                />
              </Col>
              <Col lg={3} xs={10}>
                <InputGroup style={{}}>

                  <FormControl style={{ backgroundColor: 'transparent' }}
                    placeholder="Name/ApplicationId"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <InputGroup.Text style={{ backgroundColor: 'transparent' }}>
                    <FiSearch style={{ color: 'a3a3a3' }} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>

</div>
              

              {/* </Row> */}
            </div>



            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                padding: "16px",
                height: '78%',
                // backgroundColor: 'pink',
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              {currentItems.length > 0 ? (
                currentItems.map((member) => (
                  <div
                    style={{
                      // border: "1px solid #ddd",
                      borderRadius: "5px",
                      padding: "16px",
                      width: "100%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      // display: 'flex',
                      justifyContent: "space-between",
                      alignItems: "center",
                      // textAlign: "center",
                      marginBottom: '15px',
                    }}
                  >
                    <div key={member.member_id}>
                      {adminUser.userType === "developer" && (
                        <div className="d-flex align-items-center mx-2">
                          <Button
                            className="btn btn-danger"
                            onClick={() => handleDelMember(member.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      )}

                      <div style={{ display: "flex" }}>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                            width: "35%",
                          }}
                        >
                          <Zoom>
                            <img
                              src={`${BASE_URL}/images/${member.memberPhoto}`}
                              width={60}
                              height={60}
                              alt="Member"
                              style={{ borderRadius: "5px", marginRight: "10px" }}
                            />
                          </Zoom>
                          <div style={{ textAlign: "left" }}>
                            <div>{member.memberName}</div>
                            <div>Prospect ID: {member.ApplicationId}</div>
                            {adminUser.userType === "Branch Manager" &&
                              filterStatus === "approved" && (
                                <div>{member.branchManagerStatus}</div>
                              )}
                            {adminUser.userType === "MIS" &&
                              filterStatus === "approved" && (
                                <div>{member.misStatus}</div>
                              )}




                          </div>
                        </div>
                        <div style={{ marginBottom: "8px", width: "20%" }}>
                          <MdDateRange
                            style={{
                              marginRight: '5px',
                              fontSize: '37px',
                              padding: '10px',
                              borderRadius: '5px',
                              backgroundColor: "#100D4020",
                              color: "#181818",
                            }}
                          />
                          {new Date(member.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                        </div>
                        <div style={{ marginBottom: "8px", width: "20%" }}>
                          <FaLocationDot
                            style={{
                              marginRight: '5px',
                              fontSize: '35px',
                              padding: '10px',
                              borderRadius: '5px',
                              backgroundColor: "#100D4020",
                              color: "#181818",
                            }}
                          />
                          {member.branchName}
                        </div>
                        <div style={{ marginBottom: "8px", width: "20%" }}>
                          CRO: {member.croName}
                        </div>
                        <div style={{ marginBottom: "8px", width: "20%" }}>
                          Loan Amount: Rs.
                          {member.sanctionedLoanAmountBySanctionCommittee
                            ? member.sanctionedLoanAmountBySanctionCommittee
                            : member.proposedLoanAmount}
                        </div>
                        <div style={{ width: "15%" }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic" style={{ backgroundColor: '#d4d4d8', border: 'none', color: '#181818', fontSize: '15px', outline: 'none', }}
                            >
                              {/* <FaEllipsisV /> */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ padding: "10px", }}>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <Dropdown.Item className="p-0">
                                  {/* <Button
                                variant="dark"
                                onClick={() => handleShowModal(member.id)}
                              >
                                <IoEyeSharp />
                              </Button> */}
                                </Dropdown.Item>

                                {[
                                  "Branch Manager",
                                  "Credit Manager",
                                  "MIS",
                                  "Sanction Committee",
                                  "Accounts Manager",
                                  "superadmin",
                                  "developer",
                                ].includes(adminUser.userType) &&
                                  member.cbReport && (
                                    <Dropdown.Item className="p-0">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          window.open(
                                            `${BASE_URL}/images/${member.cbReport}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <IoDocumentTextOutline />
                                      </Button>
                                    </Dropdown.Item>
                                  )}

                                {[
                                  "Branch Manager",
                                  "Credit Manager",
                                  "MIS",
                                  "Sanction Committee",
                                  "Accounts Manager",
                                  "superadmin",
                                  "developer",
                                ].includes(adminUser.userType) &&
                                  member.coApplicantCbReport && (
                                    <Dropdown.Item className="p-0">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          window.open(
                                            `${BASE_URL}/images/${member.coApplicantCbReport}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <IoDocumentTextOutline />
                                      </Button>
                                    </Dropdown.Item>
                                  )}

                                {[
                                  "Branch Manager",
                                  "Credit Manager",
                                  "MIS",
                                  "Sanction Committee",
                                  "Accounts Manager",
                                  "superadmin",
                                  "developer",
                                ].includes(adminUser.userType) &&
                                  member.pdf1 && (
                                    <Dropdown.Item className="p-0">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          window.open(
                                            `${BASE_URL}/images/${member.pdf1}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <IoDocumentTextOutline />
                                      </Button>
                                    </Dropdown.Item>
                                  )}

                                {[
                                  "Branch Manager",
                                  "Credit Manager",
                                  "MIS",
                                  "Sanction Committee",
                                  "Accounts Manager",
                                  "superadmin",
                                  "developer",
                                ].includes(adminUser.userType) &&
                                  member.pdf2 && (
                                    <Dropdown.Item className="p-0">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          window.open(
                                            `${BASE_URL}/images/${member.pdf2}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <IoDocumentTextOutline />
                                      </Button>
                                    </Dropdown.Item>
                                  )}

                                <Dropdown.Item as="div" className="p-0">
                                  <ActionButtons
                                    roleId={adminUser.userType}
                                    item={member}
                                    updateList={fetchMemberDetails}
                                    managerId={adminUser.user.id}
                                    proposedLoanAmount={
                                      member.proposedLoanAmount ?? 0
                                    }
                                    rateOfInterest={
                                      member.proposedLoanDetails
                                        ?.rateOfInterest ?? 0
                                    }
                                    tenureInMonths={
                                      member.proposedLoanDetails
                                        ?.tenureInMonths ?? 0
                                    }
                                    emiDateByCRO={
                                      member.proposedLoanDetails?.emiDate ?? null
                                    }
                                  />
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div style={{ display: 'flex', gap: '8px', width: '100%',  borderRadius: '10px' }}>

                        <div style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                          <button
                            className="view-btn"
                            onClick={() => handleShowModal(member.id)}
                          >
                           
                            View 
                            <GrFormNext style={{fontSize: '15px'}}/>
                            <GrFormNext style={{fontSize: '18px', marginLeft: '-10px'}}/>
                          </button>
                        </div>


                        {/* Progress bar */}
                        <div style={{width: '85%', display: 'flex', justifyContent: 'center'}}>

                        <div className="step-container">
                          {/* <!-- Progress bar background --> */}
                          <div className="progress-bar">
                            <div className="progress" style={{ width: '35%' }}></div>
                          </div>

                          {/* <!-- Step items --> */}
                          <div className="step active">
                            
                            <FaUser className="num"/>
                            <span className="step-label">CRO</span>
                          </div>
                          <div className="step active">
                          <FaUser className="num"/>
                            <span className="step-label">BM</span>
                          </div>
                          <div className="step active">
                          <FaUser className="num"/>
                            <span className="step-label">CO</span>
                          </div>
                          <div className="step active">
                          <FaUser className="num"/>
                            <span className="step-label">MIS</span>
                          </div>
                          <div className="step">
                          <FaUser className="num"/>
                            <span className="step-label">CM</span>
                          </div>
                          <div className="step">
                          <FaUser className="num"/>
                            <span className="step-label">SAC</span>
                          </div>
                          <div className="step">
                          <FaUser className="num"/>
                            <span className="step-label">BM-2</span>
                          </div>
                          <div className="step">
                          <FaUser className="num"/>
                            <span className="step-label">MIS-2</span>
                          </div>
                          <div className="step">
                          <FaUser className="num"/>
                            <span className="step-label">A/C M</span>
                          </div>
                          
                        </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mx-3 mt-3">
                      <MyProgressBar />
                    </div> */}
                  </div>
                ))
              ) : (
                <div className="text-center w-100">
                  No member data available.
                </div>
              )}
            </div>

            {/* Custom Pagination */}
            <nav className="custom-pagination" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '8%', width: '100%', backgroundColor: '#202020', fontSize: '14px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px -2px 15px -2px', }}>

              <button style={{ marginLeft: '15px', backgroundColor: '#323232', padding: '6px 15px', borderRadius: '6px', border: 'transparent', color: '#ECEFF1' }}>Total Data:{" "}
                {filteredMembers.length}</button>

              <ul className="pagination justify-content-end" style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', marginRight: '15px', marginTop: 'auto', marginBottom: 'auto' }}>
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="prev-btn "
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li key={currentPage} className="page-item active">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage)}
                  >
                    Page {currentPage} / {totalPages}
                  </button>
                </li>
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="prev-btn " style={{ width: '80px', borderRadius: '0 5px 5px 0' }}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>


      {showModal && (
        <MemberDetailsModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          memberId={selectedMemberId}
        />
      )}

      <DeleteConfirmationModal
        showModal={showDelModal}
        handleClose={() => setShowDelModal(false)}
        handleSubmitAlert={handleSubmitAlert}
      />
    </div>
  );
};

export default MemberDetails;

// CustomPagination.js
import React from "react";
import { Pagination } from "react-bootstrap";

const CustomPagination = ({ totalPages, currentPage, paginate }) => {
  return (
    <section style={{height: '100%',  display: 'flex',  justifyContent: 'center', alignItems: 'center', }}>

      <Pagination className=" custom-pagination" style={{marginTop: 'auto', marginBottom: 'auto', }}>
      <Pagination.First onClick={() => paginate(1)} />
      <Pagination.Prev
        onClick={() =>
          paginate(currentPage === 1 ? currentPage : currentPage - 1)
        }
      />
      {Array.from({ length: totalPages }, (_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === currentPage}
          onClick={() => paginate(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() =>
          paginate(currentPage === totalPages ? currentPage : currentPage + 1)
        }
      />
      <Pagination.Last onClick={() => paginate(totalPages)} />
    </Pagination>
    </section>
  );
};

export default CustomPagination;

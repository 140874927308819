import React from "react";
import { Table, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { LuDownload } from "react-icons/lu";

const DataTable = ({ columns, data, reportName, excelData }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      excelData.map((rowData, rowIndex) =>
        columns.reduce((acc, column) => {
          acc[column.label] = column.render(rowData, rowIndex);
          return acc;
        }, {})
      )
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    // Get current date and time in ddmmyyyy_hhmm format
    const now = new Date();
    const formattedDate = `${now.getDate().toString().padStart(2, "0")}${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${now.getFullYear()}`;
    const formattedTime = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    const fileName = `${reportName}_${formattedDate}_${formattedTime}.xlsx`;

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  return (
    <>
      <section style={{ height: "100%" }}>
        <div style={{ height: "12%",  display: 'flex',  justifyContent: 'end', alignItems: 'center' }}>

          <button onClick={exportToExcel} className="report-btn" >
          <LuDownload />
            Download Excel
          </button>
        </div>

        <div
          style={{
            height: "88%",
            overflowX: "scroll",
            
          }}
        >
          <Table style={{ height: "100%", }}>
            <thead
              style={{
                height: "25%",
                position: "sticky",
					top: 0,
					backgroundColor: "#181818",
          
					zIndex: 1,
					
              }}
            >
              <tr style={{color: '#e4e4e8', }}>
                {columns.map((column, index) => (
                  <th key={index} style={{minWidth: '60px', fontWeight: '600', textAlign: 'center',}}>{column.label}</th>
                ))}
              </tr>
            </thead>

            <tbody
              style={{
                
                height: "75%",
                overflowY: "scroll",
              }}
            >
              {data.length > 0 ? (
                data.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>{column.render(rowData, rowIndex)}</td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </section>
    </>
  );
};

export default DataTable;

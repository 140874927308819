import React from "react";
import { Modal, Button } from "react-bootstrap";

const DisburseBranchManagerModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  proposedLoanAmount,
  sanctionedLoanAmount,
  processingCharge,
  isProcessingChargePaid,
  setIsProcessingChargePaid,
  gst,
  isGstPaid,
  setIsGstPaid,
  securityDeposit,
  isSecurityDepositPaid,
  setIsSecurityDepositPaid,
  isLoanInsured,
  setIsLoanInsured,
  insuranceAmount,
  // setInsuranceAmount,
  isInsuranceAmountPaid,
  setIsInsuranceAmountPaid,
  amountToRelease,
  setAmountToRelease,
  emiDateByBranchManager,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <p>Proposed Loan Amount : Rs.{proposedLoanAmount}</p>
        <p>Sanctioned Loan Amount : Rs.{sanctionedLoanAmount}</p>
        <p>Sanctioned Loan Amount : Rs.{processingCharge}</p>
        {/* <p>
            Collected Processing Charge? :{" "}
            {isProcessingChargePaid ? "Yes" : "No"}
          </p> */}
        <p>GST Amount : Rs.{gst}</p>
        {/* <p>Collected GST? : {isGstPaid ? "Yes" : "No"}</p> */}
        <p>Security Deposit : Rs.{securityDeposit}</p>
        {/* <p>
            Collected Security Deposit? : {isSecurityDepositPaid ? "Yes" : "No"}
          </p> */}
        <p>Loan Insured? : {isLoanInsured ? "Yes" : "No"}</p>
        {isLoanInsured && (
          <>
            <p>Insurance Amount : Rs.{insuranceAmount}</p>
            {/* <p>
                Collected Insurance Amount? :{" "}
                {isInsuranceAmountPaid ? "Yes" : "No"}
              </p> */}
          </>
        )}
        <p>Loan Amount to Release : Rs.{amountToRelease}</p>
        <p>EMI Date by Branch Manager: {emiDateByBranchManager}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Disburse
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisburseBranchManagerModal;

import React, { useState } from "react";
import AddLoginCredential from "../components/AddLoginCredential";
// import DivisionalManagerList from "./DivisionalManagerList";
import AccountsManagerList from "./AccountsManagerList";
import FieldManagerList from "./FieldManagerList";
import BranchManagerList from "./BranchManagerList";
import Select from "react-select";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import CreditManagerList from "./CreditManagerList";
import CreditOfficerList from "../components/CreditOfficerList";
import MISList from "./MISList";
import SanctionCommitteeList from "./SanctionCommitteeList";

const LoginCredentials = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [refreshList, setRefreshList] = useState(false);

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption.value);
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <section>
      <div className="d-flex ">
        <div style={{width: "14%"}}>
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        </div>

        <div
           style={{
            
            height: "100vh",
            width: "86%",
            backgroundColor: "#181818",
          }}
        >
          <div style={{ height: "14%" }}>
            <DashboardHeader OpenSidebar={OpenSidebar} />
          </div>

          <div
            style={{
              height: "86%",
              width: "100%",
              borderRadius: '40px 0px 0px 0px',
              backgroundColor: "white",
            }}
          >
            <div className="search-container px-2" style={{height: '15%', }}>
             
             <div className="search-input-wrapper">
             <span className="search-icon">
                  <IoSearch />
                </span>
                <input
                  type="text"
                  placeholder="Search by any field"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                
              </div>

            <div
              className=" selectRole"
              // style={{ border: "2px solid green" }}
            >


              <Select
               value={selectedRole} onChange={handleRoleChange}
                options={[
                  { value: "Accounts Manager", label: "Accounts Manager" },
                  { value: "Sanction Committee", label: "Sanction Committee" },
                  { value: "Credit Manager", label: "Credit Manager" },
                  { value: "MIS", label: "MIS" },
                  { value: "Credit Officer", label: "Credit Officer" },
                  { value: "Branch Manager", label: "Branch Manager" },
                  {
                    value: "Customer Relationship Officer",
                    label: "Customer Relationship Officer",
                  },
                ]}
                isClearable
                placeholder="--- Select Role ---"
                
              />
            </div>

            

            <div>

                <AddLoginCredential onCredentialAdded={() => setRefreshList(!refreshList)} />

           </div>

 
           </div>

        
            <div style={{height: '85%', overflowY: 'scroll', scrollbarWidth: 'none', padding: '0 5px 0 5px'}}>

            {selectedRole === "Accounts Manager" && (
              <AccountsManagerList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}

            {selectedRole === "MIS" && (
              <MISList searchQuery={searchQuery} refreshList={refreshList} />
            )}

            {selectedRole === "Sanction Committee" && (
              <SanctionCommitteeList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}

            {selectedRole === "Branch Manager" && (
              <BranchManagerList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}

            {selectedRole === "Credit Manager" && (
              <CreditManagerList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}

            {selectedRole === "Credit Officer" && (
              <CreditOfficerList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}
            
            {selectedRole === "Customer Relationship Officer" && (
              <FieldManagerList
                searchQuery={searchQuery}
                refreshList={refreshList}
              />
            )}

            </div>

           
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginCredentials;

import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { BASE_URL } from "../env";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { adminLoginValidation } from "../components/Validations";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";

export default function LoginForm() {
  const navigate = useNavigate();
  const { adminLogin } = useAdminAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: adminLoginValidation,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/superadmin/login`,
          values
        );

        if (response.status === 200) {
          toast.success(response.data.message);
          // console.log("Login response: " + JSON.stringify(response.data));
          adminLogin(response.data);
          formik.resetForm();
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Login Failed");
      }
    },
  });

  return (
    <section className="d-flex justify-content-center formMain">
      <img
        className="formMainImg"
        src={require("../assets/pic-vv.jpeg")}
        alt="Background"
      />

      <div className="wrapper">
        <form action="#" onSubmit={formik.handleSubmit}>
          <h1>
            <img src={require("../assets/vv-logo.png")} width={"25%"} />
          </h1>
          <div className="input-box">
            <div className="icon">
              <FaUser />
            </div>
            <input
              type="text"
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            <label>User Name</label>
          </div>
          {formik.touched.username && formik.errors.username && (
            <span className="formError">{formik.errors.username}</span>
          )}

          <div className="input-box">
            {/* <i className="fas fa-lock icon"></i> */}
            <div
              className="icon"
              onClick={togglePasswordVisibility}
              style={{ position: "absolute", right: 10, cursor: "pointer" }}
            >
              {showPassword ? (
                <AiFillEyeInvisible size={20} />
              ) : (
                <AiFillEye size={20} />
              )}
            </div>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <label>Password</label>
          </div>
          {formik.touched.password && formik.errors.password && (
            <span className="formError">{formik.errors.password}</span>
          )}

          <button type="submit" className="btn1">
            Login
          </button>
        </form>
      </div>
    </section>
  );
}

{
  /* <form className="formForm " style={{display: 'none'}} onSubmit={formik.handleSubmit}>
        
<div className="formMainDiv">
  
  <div style={{padding:30}}>
    <h4 className="formH1">Hello Admin !</h4>
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid black",
        margin: 10,
      }}
    >
      <FaUser />
      <input
        className="formInput"
        type="text"
        placeholder="Username"
        required
        name="username"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.username}
      />
    </div>
    {formik.touched.username && formik.errors.username && (
      <span className="formError">{formik.errors.username}</span>
    )}

    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid black",
        margin: 10,
      }}
    >
      <RiLockPasswordFill />
      <input
        className="formInput"
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        required
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
      />
      <div
        onClick={togglePasswordVisibility}
        style={{ position: "absolute", right: 10, cursor: "pointer" }}
      >
        {showPassword ? (
          <AiFillEyeInvisible size={20} />
        ) : (
          <AiFillEye size={20} />
        )}
      </div>
    </div>

    {formik.touched.password && formik.errors.password && (
      <span className="formError">{formik.errors.password}</span>
    )}

    <button className="formButton btn btn-primary" type="submit">
      Login
    </button>
  </div>
</div>
</form> */
}

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Form, Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../env";

const ApproveCreditManagerModal = ({
  show,
  handleConfirm,
  handleClose,
  memberId,
  creditManagerId,
  proposedLoanAmount,
}) => {
  const [comment, setComment] = useState("");
  // const [loanAmount, setLoanAmount] = useState("");
  const [validationError, setValidationError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [coApplicantSelectedFile, setCoApplicantSelectedFile] = useState(null);
  const [pdf1SelectedFile, setPdf1SelectedFile] = useState(null);
  const [pdf2SelectedFile, setPdf2SelectedFile] = useState(null);

  const initialCalculations = {
    memberTotalIncome: 0,
    memberTotalMonthlyEMI: 0,
    memberTotalHouseHoldExpenses: 0,
    memberOtherExpenses: 0,
    memberMonthlyBalanceAmount: 0,
    expectedLoanAmount: 0,
    rateOfInterest: 26,
    tenureInMonths: 0,
    expectedMonthlyEmi: 0,
    coApplicantIncome: 0,
    coCoApplicantIncome: 0,
    coOtherIncome: 0,
    coBusinessExpenses: 0,
    coHouseHoldExpenses: 0,
    coOtherExpenses: 0,
    coExistingEmi: 0,
    coBalance: 0,
    eligibility: false,
  };
  const [calculations, setCalculations] = useState(initialCalculations);
  const [memberDetails, setMemberDetails] = useState(null);
  const [showSchedule, setShowSchedule] = useState(false);
  const [generatedSchedule, setGeneratedSchedule] = useState([]);

  const fetchList = useCallback(async () => {
    if (!memberId) {
      console.log("Invalid memberId");
      return;
    }
    try {
      const response = await axios.get(
        `${BASE_URL}/api/loan/formdetails/${memberId}`
      );
      setMemberDetails(response.data);
    } catch (error) {
      console.log("Error fetching list:", error);
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId) {
      fetchList();
    }
  }, [memberId, fetchList]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCoApplicantFileChange = (event) => {
    setCoApplicantSelectedFile(event.target.files[0]);
  };

  const handlePdf1FileChange = (event) => {
    setPdf1SelectedFile(event.target.files[0]);
  };

  const handlePdf2FileChange = (event) => {
    setPdf2SelectedFile(event.target.files[0]);
  };

  const performCalculations = useCallback(() => {
    if (memberDetails) {
      let totalMonthlyIncome = 0;
      totalMonthlyIncome += parseInt(
        memberDetails.businessDetails.monthlyBusinessIncome || 0
      );

      if (
        memberDetails.familyBusinessDetails.sourceOfIncome === "Salaried Person"
      ) {
        totalMonthlyIncome +=
          parseInt(memberDetails.familyBusinessDetails.monthlySalary) || 0;
      } else if (
        memberDetails.familyBusinessDetails.sourceOfIncome === "Business Person"
      ) {
        totalMonthlyIncome +=
          parseInt(memberDetails.familyBusinessDetails.monthlyBusinessIncome) ||
          0;
      }

      const totalEMI = parseInt(memberDetails.loanDetails.totalmonthlyEmi) || 0;

      // const houseHoldExpenses = calculations.memberTotalHouseHoldExpenses;
      // const otherExpenses = calculations.memberOtherExpenses;
      // const houseHoldExpenses =
      //   parseInt(memberDetails.proposedLoanDetails.houseHoldExpenses) || 0;
      // const otherExpenses =
      //   parseInt(memberDetails.proposedLoanDetails.otherExpenses) || 0;

      // const balanceAmount =
      //   totalMonthlyIncome - totalEMI - houseHoldExpenses - otherExpenses;
      const balanceAmount = parseInt(memberDetails.memberDetails.balance) || 0;

      const monthlyRate = calculations.rateOfInterest / 12 / 100;
      const expectedMonthlyEmi = Math.round(
        (calculations.expectedLoanAmount *
          monthlyRate *
          Math.pow(1 + monthlyRate, calculations.tenureInMonths)) /
          (Math.pow(1 + monthlyRate, calculations.tenureInMonths) - 1)
      );

      const isEligible = expectedMonthlyEmi <= balanceAmount;

      setCalculations((prev) => ({
        ...prev,
        memberId: memberId,
        creditManagerId: creditManagerId,
        memberTotalIncome: totalMonthlyIncome,
        memberTotalMonthlyEMI: totalEMI,
        memberMonthlyBalanceAmount: balanceAmount,
        expectedMonthlyEmi: expectedMonthlyEmi,
        eligibility: isEligible,
      }));
    }
  }, [
    memberDetails,
    memberId,
    creditManagerId,
    calculations.expectedLoanAmount,
    calculations.rateOfInterest,
    calculations.tenureInMonths,
  ]);

  useEffect(() => {
    if (memberDetails) {
      performCalculations();
    }
  }, [memberDetails, performCalculations]);

  const handleGenerateSchedule = () => {
    const { expectedLoanAmount, rateOfInterest, tenureInMonths } = calculations;
    const monthlyRate = rateOfInterest / 12 / 100;
    const emi =
      (expectedLoanAmount *
        monthlyRate *
        Math.pow(1 + monthlyRate, tenureInMonths)) /
      (Math.pow(1 + monthlyRate, tenureInMonths) - 1);

    let balance = expectedLoanAmount;
    const schedule = [];

    for (let month = 1; month <= tenureInMonths; month++) {
      const interest = balance * monthlyRate;
      const principal = emi - interest;
      balance -= principal;

      schedule.push({
        month,
        emi,
        principal,
        interest,
        balance: balance > 0 ? balance : 0,
      });
    }
    setGeneratedSchedule(schedule);
    setShowSchedule(true);
  };

  const handleConfirmApprove = async () => {
    try {
      if (!memberId) {
        setValidationError("Member ID is required.");
        return;
      }
      if (!creditManagerId) {
        setValidationError("Credit Manager ID is required.");
        return;
      }
      if (!comment) {
        setValidationError("Credit Manager Message is required.");
        return;
      }
      if (!selectedFile) {
        setValidationError("Cb Report file is required.");
        return;
      }
      const fileType = selectedFile.type;

      if (fileType !== "application/pdf") {
        setValidationError("Only PDF files are allowed.");
        return;
      }
      if (coApplicantSelectedFile) {
        const coApplicantFileType = coApplicantSelectedFile.type;
        if (coApplicantFileType !== "application/pdf") {
          setValidationError("Only PDF files are allowed.");
          return;
        }
      }
      if (pdf1SelectedFile) {
        const pdf1FileType = pdf1SelectedFile.type;
        if (pdf1FileType !== "application/pdf") {
          setValidationError("Only PDF files are allowed.");
          return;
        }
      }
      if (pdf2SelectedFile) {
        const pdf2FileType = pdf2SelectedFile.type;
        if (pdf2FileType !== "application/pdf") {
          setValidationError("Only PDF files are allowed.");
          return;
        }
      }

      const formData = new FormData();
      formData.append("member_id", memberId);
      formData.append("creditManagerId", creditManagerId);
      formData.append("creditManagerMessage", comment);
      formData.append(
        "sanctionedLoanAmountByCreditManager",
        calculations.expectedLoanAmount
      );
      formData.append("cbReport", selectedFile);
      if (coApplicantSelectedFile) {
        formData.append("coApplicantCbReport", coApplicantSelectedFile);
      }
      if (pdf1SelectedFile) {
        formData.append("pdf1", pdf1SelectedFile);
      }
      if (pdf2SelectedFile) {
        formData.append("pdf2", pdf2SelectedFile);
      }

      // Send both payload and creditAnalysisData as separate properties in the request body
      const response = await axios.post(
        `${BASE_URL}/api/uploadCbReport`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (response.data.message) {
        setValidationError("");
        handleConfirm();
      } else {
        console.log("error in response: " + response.data.error);
        setValidationError(response.data.error);
      }
    } catch (error) {
      console.log("error in catch: " + JSON.stringify(error));
      setValidationError(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="full-width-modal">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          
          <div style={{display: 'flex', width: '100%', marginBottom: '15px', }}> 

          <div style={{width: '50%'}}>
            <table className="creditManagerTable">
              <thead>
                <tr>
                  <th><u>CRO Entered Data:</u></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Income</td>
                  <td>{calculations.memberTotalIncome}</td>
                </tr>
                <tr>
                  <td>Total Monthly EMI</td>
                  <td>{calculations.memberTotalMonthlyEMI}</td>
                </tr>
              </tbody>
            </table>

            <table className="creditManagerTable">
              <thead>
                <tr>
                  <th><u>Credit Officer Entered Data:</u></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Applicant Income{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.applicantIncome}</td>
                </tr>
                <tr>
                  <td>Co Applicant Income{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.coApplicantIncome}</td>
                </tr>
                <tr>
                  <td>Other Income{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.otherIncome}</td>
                </tr>
                <tr>
                  <td>Business Expenses{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.businessExpenses}</td>
                </tr>
                <tr>
                  <td> HouseHold Expenses{" "}</td>
                  <td> {memberDetails && memberDetails.memberDetails.houseHoldExpenses}</td>
                </tr>
                <tr>
                  <td>Other Expenses{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.otherExpenses}</td>
                </tr>
                <tr>
                  <td>Existing EMI{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.existingEmi}</td>
                </tr>
                <tr>
                  <td>Balance Amount{" "}</td>
                  <td>{memberDetails && memberDetails.memberDetails.balance}</td>
                </tr>
              </tbody>
            </table>
          
          </div>

          <div style={{width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
<div style={{width: '520px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', padding: '20px', borderRadius: '15px'}}>
  
          
          <Form.Group>
            <Form.Label style={{fontWeight: '500'}}>Loan Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Expected Loan Amount"
              value={calculations.expectedLoanAmount}
              onChange={(event) => {
                setCalculations((prev) => ({
                  ...prev,
                  expectedLoanAmount: event.target.value,
                }));
                performCalculations();
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              required
            />
          </Form.Group>
          <Form.Group style={{margin: '15px 0 15px 0'}}>
            <Form.Label style={{fontWeight: '500'}}>Rate of Interest (%)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Rate of Interest (%)"
              value={calculations.rateOfInterest}
              onChange={(event) => {
                setCalculations((prev) => ({
                  ...prev,
                  rateOfInterest: event.target.value,
                }));
                performCalculations();
              }}
              readOnly={true}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{fontWeight: '500'}}>Tenure (in months)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Tenure (in months)"
              value={calculations.tenureInMonths}
              onChange={(event) => {
                setCalculations((prev) => ({
                  ...prev,
                  tenureInMonths: event.target.value,
                }));
                performCalculations();
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              required
            />
          </Form.Group>
         
          
         <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
         <div>
          <h6  style={{ textDecoration: 'underline' }}>Calculation Result </h6>
          <p style={{display: 'flex', gap: '10px',}}>
            <span>Expected Monthly EMI :</span>
            <strong>{calculations.expectedMonthlyEmi.toFixed(2)}</strong>
          </p>
          <p style={{display: 'flex', gap: '10px',}}>
            <span>Eligibility :</span>
            <strong>{calculations.eligibility ? "Eligible" : "Not Eligible"}</strong>
          </p>
          </div>

          <div style={{display: 'flex', alignItems: 'end',}}>
          <Button style={{backgroundColor: '#252525', border: 'none',}} onClick={handleGenerateSchedule}>
            Generate Schedule
          </Button>
          </div>
         </div>
</div>

          </div>

          </div>

          
          {showSchedule && (
            <>
              <div className="table-responsive">
                <table className="table table-striped text-center table-bordered">
                  <thead className="primaryColor thead_sticky" style={{backgroundColor: '#252525', color: '#fafafa', border: '#343434',}}>
                    <tr>
                      <td>S.No</td>
                      <td>Month</td>
                      <td>EMI</td>
                      <td>Principal</td>
                      <td>Interest</td>
                      <td>Balance</td>
                    </tr>
                  </thead>
                  <tbody>
                    {generatedSchedule.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.month}</td>
                        <td>{Math.round(item.emi)}</td>
                        <td>{Math.round(item.principal)}</td>
                        <td>{Math.round(item.interest)}</td>
                        <td>{Math.round(item.balance)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <div style={{display: 'flex', }}>

<div style={{width: '50%',paddingLeft: '30px', marginTop: '10px'}}>
<div>
            <p style={{  fontSize: '17px', fontWeight: '500' }}>Upload Applicant CB Report :</p>
            <input type="file" onChange={handleFileChange} />
          </div>

          <div style={{margin: '20px 0 20px 0'}}>
            <p style={{  fontSize: '17px', fontWeight: '500' }}>Upload Co-Applicant CB Report</p>
            <input type="file" onChange={handleCoApplicantFileChange} />
          </div>

          <div>
            <p style={{  fontSize: '17px', fontWeight: '500' }}>Upload PDF1</p>
            <input type="file" onChange={handlePdf1FileChange} />
          </div>

          <div style={{margin: '20px 0 20px 0'}}>
            <p style={{  fontSize: '17px', fontWeight: '500' }}>Upload PDF2</p>
            <input type="file" onChange={handlePdf2FileChange} />
          </div>

          <p className="text-danger">{validationError}</p>
</div>

<div style={{width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
          <div style={{width: '520px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', padding: '20px', borderRadius: '15px'}}>
          <Form.Group>
            <Form.Label style={{fontWeight: '500'}}>Comment</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Comment"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group style={{margin: '15px 0 15px 0'}}>
            <Form.Label style={{fontWeight: '500'}}>Proposed Loan Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Loan Amount"
              value={proposedLoanAmount}
              // onChange={(event) => setLoanAmount(event.target.value)}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{fontWeight: '500'}}>Sanctioned Loan Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Loan Amount"
              value={calculations.expectedLoanAmount}
              // onChange={(event) => setLoanAmount(event.target.value)}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              readOnly
            />
          </Form.Group>
          </div>
          </div>

          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor: '#383838', border: 'none',}} onClick={handleClose}>
          Cancel
        </Button>
        <Button style={{backgroundColor: '#252525', border: 'none',}} onClick={handleConfirmApprove}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveCreditManagerModal;
